import React,{useState,useEffect} from "react";
import { Button,Grid,TextField } from "@mui/material";
import { getData, serverURL } from ".././Services/NodeServices";
import MaterialTable from "@material-table/core";
import {useStyles} from "./DisplayAllCategoryCss"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { postData } from ".././Services/NodeServices";
import Swal from "sweetalert2";
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';

import { useContext } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from "@mui/material/styles";

const Input = styled('input')({
  display: 'none',
});


export default function DisplayAllProducts(props){  
  
  var theme =useTheme()
  const classes=useStyles()   
  const matches=useMediaQuery(theme.breakpoints.down(700))
const[products,setProducts]=useState([])
const[categoryId,setCategoryId]=useState('')
const[categoryName,setCategoryName]=useState('')
const[message,setMessage]=useState('')
    const[showBtn,setShowBtn]=useState(false)
const[open,setOpen]=useState(false)
const [images,setImages]=useState([])

const [socket,setSocket]=React.useState()



useEffect(function(){
  
   fetchAllProducts()

},[socket])

const handleOpen=(rowData)=>{
   setOpen(true)
   setImages(rowData.images)

}

const handleClose=()=>{
    setOpen(false)
}

const ImagesDialog=()=>{
    return (
        <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{color:"grey"}}
          >
            <DialogContent sx={{bgcolor:"gray"}}>
              <Grid container spacing={2}>
               {images.map((item,index)=>{
               return(<Grid item xs={5} sx={{border:1,borderStyle:"solid" ,m:2}}>
                  <img src={`${serverURL}/images/${item}`} width={'100%'}/>
                </Grid>)
               }) 
}
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} style={{ cursor: "pointer" }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
}

function displayTable() {
    return (
      <MaterialTable
      title={"Enquiry List"}
        data={products}
        style={{}}
        columns={[
            {
              title: "Product Id",
              field: "_id",
             
            },
            {
              title: " Name",
              field: "productName",
            },
          
            {
              title: "Price",
              field: "price",
            },
            {
              title: "Offer Price",
              field: "offerprice",
            },
            {
              title: "Hot Selling",
              field: "hotSelling",
            },
            {
              title: "New Arrival",
              field: "newArrival",
            },
            {
              title: "Images",
              render: (rowData) => (
                <div>
                  <Button variant="contained" onClick={() => handleOpen(rowData)}>
                    Show
                  </Button>
                </div>
              ),
            },
           
            
           
        ]}
        actions={[
          
        
        ]}
       
      />
    );
  }



    const fetchAllProducts=async()=>{
        const result=await getData('products/displayAllProduct')
        
        setProducts(result.data)
    }


    

    return(
      
        
        <Grid container spacing={2} style={{  display:"flex",
        justifyContent:'center',
        alignItems:'center'}}>
          <Grid item xs={12} sm={8} style={{marginTop:20,fontSize:matches?10:20}}>
        {displayTable()}</Grid>  
        {ImagesDialog()}</Grid>

    )
    
}