import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { postData,getData } from '../../../Services/NodeServices';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
const StandeeHome = () => {

  const {id}=useParams();
  
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down(600));
  const tablet = useMediaQuery(theme.breakpoints.down(960));
  const[result,setResult]=useState('')
  const[display,setDisplay]=useState('none')
  const navigate=useNavigate();

  const checkTagId=async()=>{
    const formData=new FormData;
    formData.append("tagId",id)
    const response=await postData('/standee/chkTagId',formData,true)
    setResult(response.status)
    if(response.status=='false'){
      setDisplay('block')
    }
    else{
      
        if(response.data.status=='Active' && response.data.link!=''){
            window.open(`${response.data.link}`,"_self")
        }else{
          navigate('/digitalcardlogin')
        }
    }
    
  }

  useEffect(()=>{
   checkTagId()
  },[])
  



  return (
    <Grid>
       <Grid container spacing={2} sx={{display:display}}>
        <Grid
            xs={12}
            item
            sx={{
              mt: "5vh",
              mb: "4vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
        <Paper
              elevation={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 600,
                borderRadius: 4,
              }}
            >
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: mobile ? "100%" : tablet ? "70%" : "100%",
                  margin: 1,
                }}
              >
                <Grid item xs={12}>
                    <Typography textAlign={'center'} fontWeight={700} fontSize={20}>Activation of Standee Tag</Typography>
                </Grid>
               
              
                <Grid item xs={12}  sx={{display:"flex",justifyContent:"center"}}>
                    <Button variant='contained' onClick={()=>navigate('/standeelogin',{state:{id:id}})}>Log In</Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography textAlign={'center'} fontWeight={700} fontSize={20}>Don't Have an Account</Typography>
                </Grid>
                <Grid item xs={12}  sx={{display:"flex",justifyContent:"center"}}>
                    <Button variant='contained' onClick={()=>navigate('/standeesignup',{state:{tagId:id}})}>Sign Up</Button>
                </Grid>


              </Grid>
              </Paper>
</Grid>
        </Grid>
             
    </Grid>
  )
}

export default StandeeHome
