import {
  Grid,
  Typography,
  Divider,
  TextField,
  IconButton,
  Button,
} from "@mui/material";
import img1 from "../../assets/hgf.jpg";
import img4 from "../../assets/review.jpg";
import img3 from "../../assets/c2.jpg";
import img9 from "../../assets/right.png";
import img34 from "../../assets/jkl.png";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import RemoveIcon from "@mui/icons-material/Remove";
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import PinterestIcon from '@mui/icons-material/Pinterest';
import AddIcon from "@mui/icons-material/Add";
import google from "../../assets/google-logo.png";
import insta from "../../assets/insta.png";
import facebook from "../../assets/facebook.png";
import ReactPlayer from "react-player";
import React, { useState } from "react";
import Slider from "react-slick";
import { useLocation, useParams } from "react-router-dom";
import { postData, serverURL } from "../../../../Services/NodeServices";
import { useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";

const googleData = {

}

export default function Enter() {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const [data, setData] = useState();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState(1);
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const { _id } = useParams()
  const fetchProduct = async () => {
    var formData = new FormData
    formData.append('_id', _id)
    const response = await postData('products/getProductById', formData, true)
    console.log(response.data.images)
    setData(response.data)
    setShow(true)
  }
  

  useEffect(() => {
    fetchProduct()
  }, [])
  const handleRemove = () => {
    if (value != 1) {
      setValue(value - 1);
    }
  };
  const handleAdd = () => {
    setValue(value + 1);
  };

  return (
    <>
      <Navbar />
      {show && (<Grid container spacing={2} sx={{ height: "100vh" }}>
        <Grid
          item
          xs={11.5}
          md={6}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            maxHeight: "800px",
          }}
        >
          <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
            {data.images.map((item) => (
              <Grid item xs={12} sx={{ ml: 15 }}>
                <img src={`${serverURL}/images/${item}`} width={"70%"} />
              </Grid>
            ))}
          </Slider>
          <Slider
            asNavFor={nav1}
            ref={(slider2) => setNav2(slider2)}
            slidesToShow={3}
            swipeToSlide={true}
            focusOnSelect={true}
          >
            {data.images.map((item) => (
              <Grid item xs={12}>
                <img src={`${serverURL}/images/${item}`} width={"100%"} />
              </Grid>
            ))}
          </Slider>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{
            maxHeight: "800px",
            overflowY: "scroll", // Always show a scrollbar, but make it the same color as the background to hide it
            scrollbarWidth: "none", // Hide the scrollbar in Firefox
            "&::-webkit-scrollbar": {
              width: "0", // Hide the scrollbar in WebKit (Chrome, Safari, and Opera)
            },
          }}
        >
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
              <img src={data.subCategoryName == "Google" ? google : data.subCategoryName == "Instagram" ? insta : data.subCategoryName == "Facebook" ? facebook : ''} style={{ width: 100 }} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", fontSize: 40 }}>
              {data.productName}
            </Grid>
            <Grid item xs={12} sx={{ color: "green", textAlign: "center", fontSize: 30 }}>
              ₹{data.offerprice}
            </Grid>
            <Grid item xs={12} sx={{ color: "red", textAlign: "center", textDecoration: 'line-through', fontSize: 25 }}>
              ₹{data.price}
            </Grid>
            <Grid item xs={12} sx={{ fontSize: 10, textAlign: "center" }}>
              Delivery Time: 4-6 Working days
            </Grid>
            <Grid item xs={12} sx={{ fontSize: 30, textAlign: "center" }}>
              Get more Google reviews with review NFC{" "}
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", fontSize: 30 }}>
              Cards
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", fontSize: 15 }}>
              Getting Google reviews from customers has never been easier!
              <br />
              With the Contactless Google Reviews NFC Cards, you can effortlessly
              gain new Google reviews <br /> directly on-site.
              <br />
              The eye-catching design of the Google Review NFC Cards sparks
              customers’ interest, and the
              <br /> Call-To-Action encourages them to review your business on
              Google.
              <br />
              All your customers need to do is hold their smartphone near the NFC
              Cards, and a pop-up will
              <br /> open on their phone, leading them directly to your review
              form!
              <br />
              If customers’ smartphones do not have NFC functionality, an
              alternative QR code is available on <br /> the back of the NFC
              Cards, which can be scanned directly using the smartphone camera –
              in <br /> both cases, no separate app is required!
              <br />
            </Grid>
            <Grid item xs={12} sx={{ fontSize: 10, textAlign: "center" }}>
              Delivery Time: 4-6 Working days
            </Grid>

            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: 32,
                  textAlign: "center",
                }}
              >
                How NFC Cards help you get more Google reviews
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center", fontSize: 15 }}>
                The NFC Cards eliminate the biggest hurdles for both you and your
                customers when it comes to <br /> Google reviews: No more
                uncomfortable asking or pleading. The Call-To-Action takes care of
                requesting Google <br />
                reviews for you. No more forgetting, as customers are reminded to
                leave a Google review.
                <br />
                No searching or difficulty: The NFC link and the Google Review QR
                code directly lead to your
                <br /> review form (which we create for you)
                <br />
                Tangible: The NFC Cards make the review process more tangible.
                <br />
                No extra app required.
                <br />
                Eyecatcher: Modern design
                <br />
                We handle the creation and linking of the NFC link and QR code for
                direct reviews on your <br />
                Google profile!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: 32,
                  textAlign: "center",
                }}
              >
                How it works
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <ReactPlayer url="https://youtu.be/snwf2nrMyRI?si=oaebKxPGf1BuO218" />
            </Grid>
            <Grid item xs={4} sx={{ flexDirection: "row", mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <img src={img9} />
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ fontSize: 15 }}>
                    No subscription or
                    <br /> recurring fees
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} sx={{ flexDirection: "row", mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <img src={img9} />
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ fontSize: 15 }}>
                    Individually made for
                    <br /> you{" "}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={4} sx={{ flexDirection: "row", mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <img src={img9} />
                </Grid>
                <Grid item xs={10}>
                  <Typography sx={{ fontSize: 15 }}>
                    Secure - SSL protected
                    <br />
                    checkout
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: 25, fontFamily: "poppins" }}>
                customize it
              </Typography>
              <Typography>
                Please configure the product to suit you. We offer you different
                options and variations for this. We create the QR code and NFC tag
                link for your Google review for you based on your business name
                and location. There is no need to add a custom link by yourself.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: 25 }}>Company*</Typography>
              <Typography>
                Please enter the name of your company on Google.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Typography sx={{ fontSize: 25 }}>City*</Typography>
              <Typography>
                Please tell us in which city your company is located to avoid
                confusion if the company name exists more than once.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={10} sx={{ backgroundColor: "lightblue", mt: 3 }}>
              <Typography sx={{ fontSize: 25 }}>Information</Typography>
              <Typography>
                The review QR code for your product is created by us, based on
                your business information (business name and location) that you
                can enter above. You dont need to link your profile or upload your
                own QR code. This service is integrated and without any monthly
                fees / subscription.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: "center", fontSize: 25 }}>
                Final total
              </Typography>
              <Typography sx={{ textAlign: "center", color: "#e67e22" }}>
                ₹{value * data.offerprice}
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{ display: "flex", fontSize: 30, justifyContent: "center" }}
            >
              <Grid>
                <IconButton
                  sx={{
                    color: "#001E3C",
                    bgcolor: "white",
                    m: 1,
                    "&:hover": { bgcolor: "white" },
                  }}
                  onClick={() => handleRemove()}
                >
                  <RemoveIcon />
                </IconButton>
              </Grid>{" "}
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#000",
                }}
              >
                {value}
              </Grid>
              <Grid>
                <IconButton
                  sx={{
                    color: "#001E3C",
                    bgcolor: "white",
                    m: 1,
                    "&:hover": { bgcolor: "white" },
                  }}
                  onClick={() => handleAdd()}
                >
                  <AddIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Button sx={{ bgcolor: "black", color: "white" }}>
                ADD To Cart
              </Button>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <img src={img34} />
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center", fontSize: 15 }}>
              <Typography>Pay as it suits you best.</Typography>
            </Grid>
            <Divider sx={{ width: '100%', color: 'black' }} />
            <Grid item xs={12} sx={{ fontSize: 15 }}>
              <Typography>United States (US) Dollar ($)-USD</Typography>
            </Grid>

            <Grid item xs={12} sx={{ fontSize: 13, textAlign: 'center' }}>
              <Typography >Sku:trunfc20231006</Typography>
              <Typography>Categories: Automotive, Beauty, Doctors & Medical industry, Gastronomy, Google, Google Review Business Cards, Google Review NFC Business Cards, Google Review NFC Cards, Hotel industry, NFC Cards, NFC products, Restaurant, Retail</Typography>
            </Grid>

            <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Typography>SHARE:</Typography>
              <FacebookIcon />
              <TwitterIcon />
              <PinterestIcon />
            </Grid>





          </Grid>
        </Grid>
        <Divider sx={{ width: '100%', color: 'black', mt: 5 }} />

        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Description</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography sx={{ fontSize: 40 }}>Description</Typography>
              <Typography sx={{ fontSize: 35 }}>Get More Google Reviews with truzzer’s Google Review NFC QR Code Cards “Tap”!</Typography>

              <Typography>
                With the empfehlio Google Review Cards featuring NFC links, you can quickly receive positive reviews from your satisfied customers right at your business location.
                -Weather-resistant
                -Shows your customers that they can find you on Google too
                -Custom-made specifically for you
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Additional Information</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                malesuada lacus ex, sit amet blandit leo lobortis eget.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>ABout Brand</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Discover the truzzer products to get more Google reviews!
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography>Shipping & Production</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                This product is custom made for you. The duration from dispatch to delivery to you is approx. 4 to 5 working days.

              </Typography>
            </AccordionDetails>
          </Accordion>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>Reviews</Typography>
              <Typography>There are no reviews yet.</Typography>

            </Grid>

            <Grid item xs={6}>
              <Typography>Show only reviews in English (0)</Typography>
              <Typography>BE THE FIRST TO REVIEW “GOOGLE REVIEW NFC CARD “TAP” WITH GOOGLE REVIEW QR CODE”</Typography>
              <Typography>Your email address will not be published. Required fields are marked *</Typography>
              <Typography>Your rating *12345</Typography>

              <Typography>Your Review</Typography>
              <Typography>Name*</Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />

              <Typography>Email*</Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
              />

              <Checkbox {...label} defaultChecked />
              <Typography>I have Read and accept the private policy</Typography>
              <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                <Checkbox {...label} defaultChecked />
                <Typography sx={{ mt: 1 }}>Save my name, email, and website in this browser for the next time I comment.</Typography>
              </Grid>
              <Typography>You have to be logged in to be able to add photos to your review.</Typography>
              <Button sx={{ bgcolor: "black", color: "white" }}>
                SUBMIT
              </Button>
            </Grid>






          </Grid>




          <Footer />


        </Grid>


      </Grid>)}

    </>
  );
}
