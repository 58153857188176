import { Grid, Button, Typography, TextField, useTheme, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import Navbar from '../UserComponents/Navbar'
import Footer from '../UserComponents/Footer'
import { useNavigate } from 'react-router-dom'
import { Avatar } from '@mui/material'
import { postData } from '../../../../Services/NodeServices'
import { serverURL } from '../../../../Services/NodeServices'
import InputAdornment from '@mui/material/InputAdornment';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import { Uploader } from "uploader";
import { UploadButton } from "react-uploader";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dropzone from 'react-dropzone';
import { confirmAlert } from 'react-confirm-alert'; // Import
import BeenhereIcon from '@mui/icons-material/Beenhere';
import AvatarEditor from 'react-avatar-editor'
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
const Information = () => {

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down(600));
    const tablet = useMediaQuery(theme.breakpoints.down(960));
    const [crop, setCrop] = useState({}); // Stores the crop data
    const [image, setImage] = useState(null);
    const [open, setOpen] = React.useState(false);

    const navigate = useNavigate()
    const cardId = window.localStorage.getItem("CardId")
    const userId = window.localStorage.getItem("userId")
    const [Icon, setIcon] = React.useState({ url: "", bytes: "" });
    const [companyCover, setCompanyCover] = React.useState({ url: "", bytes: "" });
    const [fullName, setFullName] = useState('')
    const [position, setPosition] = useState('')
    const [phone, setPhone] = useState()
    const [alternatePhone, setAlternatePhone] = useState()
    const [whatsappNo, setWhatsappNo] = useState()
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [website, setWebsite] = useState('')
    const [education, setEducation] = useState('')
    const [hobby, setHobby] = useState('')
    const [fbLink, setFbLink] = useState('')
    const [twitterLink, setTwitterLink] = useState('')
    const [instaLink, setInstaLink] = useState('')
    const [linkedlnLink, setLinkedlnLink] = useState('')
    const [threadLink, setThreadLink] = useState('')
    const [skypeLink, setSkypeLink] = useState('')
    const [zomatoLink, setZomatoLink] = useState('')
    const [discordLink, setDiscordLink] = useState('')
    const [dribbleLink, setDribbleLink] = useState('')
    const [behanceLink, setBehanceLink] = useState('')
    const [playstoreLink, setPlayStoreLink] = useState('')
    const [appstoreLink, setAppStoreLink] = useState('')
    const [youtubeLink, setYoutubeLink] = useState('')
    const [pinterestLink, setPinterestLink] = useState('')
    const [location, setLocation] = useState('')
    const [companyEstDate, setCompanyEstDate] = useState('')
    const [about, setAbout] = useState('')
    const [paytmNumber, setPaytmNumber] = useState('')
    const [googlePayNumber, setGooglePayNumber] = useState('')
    const [phonePeNumber, setPhonePeNumber] = useState('')
    const [googleMapLink, setGoogleMapLink] = useState('');

    const [themeId, setThemeId] = useState('')
    const [message, setMessage] = useState('')
    const [type, setType] = useState('')
   
  const [Editor, setEditor] = useState(null);
  const [image1, setimage1] = useState(null);
  const [file, setfile] = useState(null);
  const [DBimage, setDBimage] = useState(null);
  const [img,setImg]=useState(null)
  const [name1,setName1]=useState()
  const setEditorRef = (editor) => {
    setEditor(editor);
  };
  //convet FILE form URL
  const DataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };
  //canvert the data Buffer Array into image
  const arrayBufferToBase64 = (buffer) => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    return window.btoa(binary);
  };

  const submit = (e) => {
    e.preventDefault();
    if (Editor) {
      const url = Editor.getImageScaledToCanvas().toDataURL();
      setimage1(url);
      alert(url)

      setfile(DataURLtoFile(url, name1));
      console.log('jhhbdeb',DataURLtoFile(url, name1));
    }
    setOpen(false)
  };
  const handleClose = () => {
    setOpen(false);
  };



  const DialogComponent=()=>{
    return(
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Crop Image
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
        {img &&(  <AvatarEditor
          ref={setEditorRef}
          image={img}
          width={400}
          height={200}
          border={50}
          color={[128, 128, 128]}
          borderRadius={0}
          scale={1.2}
          rotate={0}
        />)}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={submit}>
            Save changes
          </Button>
        </DialogActions>
      </BootstrapDialog>
    )
  }

    const fetchCardDetail = async () => {
        var formData = new FormData()
        formData.append("customerId", userId)
        var result = await postData('carddetails/getcardDetails', formData, true)
        console.log(result.data)
        setFullName(result.data.fullname)
        setPosition(result.data.position)
        setPhone(result.data.phoneNumber)
        setAlternatePhone(result.data.AlternatePhoneNumber)
        setWhatsappNo(result.data.WhatsappNo)
        setAddress(result.data.Address)
        setEmail(result.data.Email)
        setFbLink(result.data.fbLink)
        setInstaLink(result.data.igLink)
        setLinkedlnLink(result.data.LinkdnLink)
        setPinterestLink(result.data.PinterestLink)
        setGoogleMapLink(result.data.GoogleMapLink)
        
        setTwitterLink(result.data.TwitterLink)
        setYoutubeLink(result.data.YoutubeLink)
        setPaytmNumber(result.data.paytmNumber)
        setPhonePeNumber(result.data.phonepenumber)
        setGooglePayNumber(result.data.Googlepaynumber)
        setEducation(result.data.education)
        setHobby(result.data.hobby)
        setThreadLink(result.data.threads)
        setSkypeLink(result.data.skype)
        setZomatoLink(result.data.zomato)
        setDiscordLink(result.data.discord)
        setDribbleLink(result.data.dribble)
        setBehanceLink(result.data.behance)
        setPlayStoreLink(result.data.playstore)
        setAppStoreLink(result.data.appstore)
       
        if (result.data.website != 'undefined') { setWebsite(result.data.website) }
        if (result.data.location != 'undefined') { setLocation(result.data.location) }
        setCompanyEstDate(result.data.CompanyEstDate)
        setAbout(result.data.AboutUs)
        setIcon({ url: `${serverURL}/images/${result.data.companylogo}`, bytes: " " });
        setCompanyCover({ url: `${serverURL}/images/${result.data.companyCoverImage}`, bytes: " " })
        setThemeId(result.data.themeid)
        //setType(result.data.companyCoverImage.slice(result.data.companyCoverImage.length-3))
        setGoogleMapLink(result.data.GoogleMapLink)
    }
    React.useEffect(() => {

        fetchCardDetail()
    }, [])

    const handleSubmit = async () => {
      
           
            var formData = new FormData()
            formData.append('_id', cardId)
            formData.append('companylogo', Icon.bytes)
            formData.append('companyCover', companyCover.bytes)
            formData.append('fullname', fullName)
            formData.append('position', position)
            formData.append('phoneNumber', phone)
            formData.append('AlternatePhoneNumber', alternatePhone)
            formData.append('whatsappNo', whatsappNo)
            formData.append('Address', address)
            formData.append('Email', email)
            formData.append('website', website)
            formData.append('location', location)
            formData.append('AboutUs', about)
            formData.append('fbLink', fbLink)
        formData.append('igLink', instaLink)
        formData.append('TwitterLink', twitterLink)
        formData.append('YoutubeLink', youtubeLink)
        formData.append('PinterestLink', pinterestLink)
        formData.append('LinkdnLink', linkedlnLink)
        formData.append('threads', threadLink)
        formData.append('education', education)
        formData.append('hobby', hobby)
        formData.append('skype', skypeLink)
        formData.append('zomato', zomatoLink)
        formData.append('discord', discordLink)
        formData.append('dribble', dribbleLink)
        formData.append('behance', behanceLink)
        formData.append('playstore', playstoreLink)
        formData.append('appstore', appstoreLink)
        formData.append('paytmNumber', paytmNumber)
        formData.append('Googlepaynumber', googlePayNumber)
        formData.append('phonepenumber', phonePeNumber)
        formData.append('GoogleMapLink', googleMapLink)

            var response = await postData("carddetails/updatepersonelinfo", formData, true);
            if (response.status) {
                themeId=='8'?navigate('/hotellinks'):themeId=='11'?navigate('/preview'):themeId=='9'?navigate('/menulink'):navigate('/links')
            } else {

            }

        
    }

    const handleIcon = (event) => {
        setIcon({
            url: URL.createObjectURL(event.target.files[0]),
            bytes: event.target.files[0],
        });
    };
    const handleCover = (event) => {
        console.log("nrfdnenedjn",event.target.files[0].type)
        setCompanyCover({
            url: URL.createObjectURL(event.target.files[0]),
            bytes: event.target.files[0],
        });
        setType(event.target.files[0].type.slice(event.target.files[0].type.length-3))
       
    };
    return (
        <Grid>
            <Navbar />
            <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', paddingX: "20px" }}>
                <Grid item xs={4} sx={{ marginTop: "2vh", marginBottom: "2vh" }}>
                    <Button sx={{
                        borderRadius: 10,
                        backgroundImage: "linear-gradient(to top left,#48dbfb,#001e3c)",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        textAlign: "center",
                        alignItems: "center",
                    }} onClick={() => navigate('/themepage')} variant='contained'><NavigateBeforeIcon />Back</Button>
                </Grid>
                <Grid item xs={4} sx={{display:'flex',justifyContent:"center", marginTop: "2vh", marginBottom: "2vh" }}>
                    <Button sx={{
                        borderRadius: 10,
                        backgroundImage: "linear-gradient(to top left,#48dbfb,#001e3c)",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        textAlign: "center",
                        alignItems: "center",
                    }} onClick={() => handleSubmit()} variant='contained'>Save<BeenhereIcon /></Button>
                </Grid>
                <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: "2vh", marginBottom: "2vh" }}>
                    <Button sx={{
                        borderRadius: 10,
                        backgroundImage: "linear-gradient(to top left,#48dbfb,#001e3c)",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-evenly",
                        textAlign: "center",
                        alignItems: "center",
                    }} onClick={() => themeId=='8'?navigate('/hotellinks'):themeId=='11'?navigate('/preview'):themeId=='9'?navigate('/menulink'):navigate('/links')} variant='contained'>Skip<NavigateNextIcon /></Button>
                </Grid>
                <Grid container spacing={2} sx={{ width: mobile ? "90%" : '50%' }}>
                    <Grid item xs={12}>
                        <Typography textAlign='center' sx={{ fontSize: { xs: "1.4rem", md: 28 } }}>Company Details</Typography>
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                    {type=="mp4"?<video
            autoPlay
            loop
            muted
            style={{
              objectFit: 'fill',
              width: "60%",
              height: 250,
              marginLeft: -10

            }}
          >
            <source src={companyCover.url} type="video/mp4" />
            
          </video>:<Avatar
                            fullWidth
                            variant="rounded"
                            alt="Remy Sharp"
                            src={ companyCover.url}
                            sx={{ width: 400, height: 200 }}
                        />}
                      
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <label htmlFor="icon-button-file">
                    <input
                      style={{ display: "none" }}
                      accept="image/*, video/*"
                      id="icon-button-file"
                      type="file"
                      onChange={handleCover}
                    />
                    <Button
                      color="primary"
                      aria-label="upload picture"
                      variant='contained'
                      component="span"
                    >
                      Upload Cover
                    </Button>
                  </label>
                    </Grid>


                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                        <Avatar
                            fullWidth
                            variant="rounded"
                            alt="Remy Sharp"
                            src={Icon.url}
                            sx={{ width: 120, height: 120, borderRadius: '60%' }}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                    <label htmlFor="icon-button-file1">
                    <input
                      style={{ display: "none" }}
                      accept="image/*"
                      id="icon-button-file1"
                      type="file"
                      onChange={handleIcon}
                    />
                    <Button
                      color="primary"
                      aria-label="upload picture"
                      variant='contained'
                      component="span"
                    >
                      Upload Logo
                    </Button>
                  </label>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography textAlign='center' sx={{ fontSize: { xs: 14, md: 28 } }}>Personel Details</Typography>
                    </Grid>




                    {themeId=='11'?
                    <>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={fullName} onChange={(e) => setFullName(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Full Name'   />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={email} type='email' onChange={(e) => setEmail(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Email Id'   />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={phone} type='number' InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CallIcon />
                                </InputAdornment>
                            ),
                        }} onChange={(e) => setPhone(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Phone Number'   />
                    </Grid>
                    <Grid item xs={12}  style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={alternatePhone} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CallIcon />
                                </InputAdornment>
                            ),
                        }} type='number' onChange={(e) => setAlternatePhone(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Alternate Number(Optional)'/>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={whatsappNo} type='number' InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <WhatsAppIcon />
                                </InputAdornment>
                            ),
                        }} onChange={(e) => setWhatsappNo(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label= 'Whatsapp Number'   />
                    </Grid>
                    
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={position} onChange={(e) => setPosition(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Position/Designation'   />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={location} onChange={(e) => setLocation(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Location Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={googleMapLink} onChange={(e) => setGoogleMapLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Review Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={education} onChange={(e) => setEducation(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Education(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={hobby} onChange={(e) => setHobby(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Hobby(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={website} onChange={(e) => setWebsite(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Website(Optional)' />
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={fbLink} onChange={(e) => setFbLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Facebook Username(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={twitterLink} onChange={(e) => setTwitterLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Twitter Username(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={instaLink} onChange={(e) => setInstaLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Instagram Username(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={linkedlnLink} onChange={(e) => setLinkedlnLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Linkedln Username(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={threadLink} onChange={(e) => setThreadLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Thread Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={skypeLink} onChange={(e) => setSkypeLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Skype Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={pinterestLink} onChange={(e) => setPinterestLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Pinterest Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={zomatoLink} onChange={(e) => setZomatoLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Zomato Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={discordLink} onChange={(e) => setDiscordLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Discord Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={dribbleLink} onChange={(e) => setDribbleLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Dribble Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={behanceLink} onChange={(e) => setBehanceLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Behance Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={playstoreLink} onChange={(e) => setPlayStoreLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Play Store Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={appstoreLink} onChange={(e) => setAppStoreLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='App Store Link(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={youtubeLink} onChange={(e) => setYoutubeLink(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Youtube Channel Name(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
            <TextField value={paytmNumber} onChange={(e) => setPaytmNumber(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='Paytm Upi id(Optional)' />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
            <TextField value={googlePayNumber} onChange={(e) => setGooglePayNumber(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='GooglePay Upi id(Optional)' />
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
            <TextField value={phonePeNumber} onChange={(e) => setPhonePeNumber(e.target.value)} sx={{ width: { xs: '100%', md: '60%' } }} label='PhonePe Upi id(Optional)' />
          </Grid>

                    
                    
                    
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={about} onChange={(e) => setAbout(e.target.value)} rows={5} multiline sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='About Us'   />
                    </Grid>
                    </>:
                    <>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={fullName} onChange={(e) => setFullName(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Full Name'   />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={position} onChange={(e) => setPosition(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Position/Designation'   />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={phone} type='number' InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CallIcon />
                                </InputAdornment>
                            ),
                        }} onChange={(e) => setPhone(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Phone Number'   />
                    </Grid>
                    <Grid item xs={12}  style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={alternatePhone} InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CallIcon />
                                </InputAdornment>
                            ),
                        }} type='number' onChange={(e) => setAlternatePhone(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Alternate Number(Optional)'/>
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={whatsappNo} type='number' InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <WhatsAppIcon />
                                </InputAdornment>
                            ),
                        }} onChange={(e) => setWhatsappNo(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label= 'Whatsapp Number'   />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={address} onChange={(e) => setAddress(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Address'   />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={email} type='email' onChange={(e) => setEmail(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Email Id'   />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={website} onChange={(e) => setWebsite(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Website(Optional)' />
                    </Grid>
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={location} onChange={(e) => setLocation(e.target.value)} sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='Location Link(Optional)' />
                    </Grid>
                    
                    
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <TextField value={about} onChange={(e) => setAbout(e.target.value)} rows={5} multiline sx={{ width: mobile?"95%":tablet?"100%":'60%' }} label='About Us'   />
                    </Grid>
                    </>
                    }
    
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: "center", marginBottom: 8 }}>
                        <Button sx={{
                            borderRadius: 10,
                            paddingInline:"30px",
                            backgroundImage: "linear-gradient(to top left,#48dbfb,#001e3c)",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-evenly",
                            textAlign: "center",
                            alignItems: "center",
                        }} variant='contained' onClick={() => handleSubmit()}>Next</Button>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        {DialogComponent()}
        </Grid>
    )
}

export default Information
